import React from 'react'
import { InputAdornment, TextField, IconButton, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import './AddTaskInput.css'


function AddTaskInput({ taskInput, setTaskInput }) {
    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder="Add task"
            value={taskInput}
            onChange={(e) => setTaskInput(e.target.value)}
            sx={{}}
            className='addTaskInput'
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton className='leftIcon' edge="start" disabled>
                            <AddIcon />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton type='sunmit' className='rightIcon' edge="end" disabled={taskInput.length === 0}>
                            <SendIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />

    );

}

export default AddTaskInput
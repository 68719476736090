import React, { useEffect, useState } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { Box, Button, Card, CardHeader, Container, Divider, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import Loader from '../../components/common/Loader';

function MyAccount() {
    const [userData, setUserData] = useState({});
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState('')
    const accessToken = useSelector((state) => state.auth.accessToken);
    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const headers = { 'Authorization': `Bearer ${accessToken}` };
            const { id } = jwtDecode(accessToken);
            const url = process.env.REACT_APP_API_URL + '/user/' + id;
            try {
                const res = await axios.get(url, { headers });
                setUserData(res.data)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        getData();
    }, [])

    const updateProfile = async () => {
        const headers = { 'Authorization': `Bearer ${accessToken}` };
        const { id } = jwtDecode(accessToken);
        const url = process.env.REACT_APP_API_URL + '/user/' + id;
        const body = {
            name: userData.name,
        }
        try {
            await axios.put(url, body, { headers });
            setSuccess('Profile updated successfully')
        } catch (error) {

        }
    }
    const changePassword = async () => {
        const url = process.env.REACT_APP_API_URL + '/auth/change-password'
        const body = {
            username: userData.username,
            oldPassword: currentPassword,
            newPassword
        }
        try {
            await axios.post(url, body);
            setSuccess('Password changed successfully')
            setCurrentPassword('')
            setNewPassword('')
        } catch (error) {
            setSuccess('Incorrect Password')
        }
    }
    return (
        <>
            {loading ? <Loader /> :
                <div>
                    <Container maxWidth="md">
                        <Typography variant="h4" sx={{ my: 2 }}>My Account</Typography>
                        <Divider />
                        <Box>
                            <Card sx={{ my: 2 }} >
                                <CardHeader titleTypographyProps={{ style: { fontSize: '1.2rem' } }} title='Basic Details' />
                                <div className='md-card-container'>
                                    <TextField label="Name" variant="outlined" sx={{ width: '100%', mb: 2 }} value={userData?.name} onChange={(e) => setUserData({ ...userData, name: e.target.value })} />
                                    <Box sx={{ width: '100%', mb: 1 }}>
                                        <TextField label="Username" variant="outlined" sx={{ width: '100%' }} disabled value={userData.username} />
                                        <Typography variant='caption text' fontSize={13}>Username cant be changed for now. Update coming soon.</Typography>
                                    </Box>
                                    <Box sx={{ width: '100%', mb: 1 }}>
                                        <TextField label="Email" variant="outlined" sx={{ width: '100%' }} diabled value={userData.email} />
                                        <Typography variant='caption text' fontSize={13}>Email cant be changed for now. Update coming soon.</Typography>
                                    </Box>
                                    <Box sx={{ width: '100%', mb: 1 }}>
                                        <Button variant='contained' onClick={() => updateProfile()}>Update</Button>
                                    </Box>
                                </div>
                                <CardHeader titleTypographyProps={{ style: { fontSize: '1.2rem' } }} title='Change Password' />
                                <div className='md-card-container'>
                                    <TextField type="password" label="Current Password" variant="outlined" sx={{ width: '100%', mb: 2 }} value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />
                                    <TextField type="password" label="New Password" variant="outlined" sx={{ width: '100%', mb: 2 }} value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                    <Box sx={{ width: '100%', mb: 2 }}>
                                        <Button variant='outlined' disabled={currentPassword === '' || newPassword === ''} onClick={() => changePassword()}>Update password</Button>
                                    </Box>
                                </div>
                            </Card>
                        </Box>
                    </Container>
                    {success != '' &&
                        <Snackbar
                            open={true}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            autoHideDuration={3000}
                            onClose={() => setSuccess(false)}
                            message={success}
                            action={() => { }}
                        />
                    }
                </div>

            }
        </>
    )
}

export default MyAccount
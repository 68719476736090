import React from 'react'
import { Breadcrumbs, Link, Typography } from '@mui/material'

function Breadcrumb({ parentLabel, parentLink, childLabel }) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
                Home
            </Link>
            <Link
                underline="hover"
                color="inherit"
            >
                {parentLabel}
            </Link>
            <Typography color="text.primary">{childLabel}</Typography>
        </Breadcrumbs>
    )
}

export default Breadcrumb
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Card, Container, Divider, Box, Typography, Checkbox, TextField, Button, Snackbar, CircularProgress, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import NumberCard from '../../components/common/NumberCard';
import AddIcon from '@mui/icons-material/Add';


import './Home.css'
import TaskCard from '../../components/common/TaskCard';
import Loader from '../../components/common/Loader';
import { getGreeting } from '../../utilities/commonFunctions';
import AddTaskInput from '../../components/common/AddTaskInput';

function Home() {
    const lists = useSelector((state) => state.list.lists);
    const [tasks, setTasks] = useState([]);
    const [taskInput, setTaskInput] = useState("")
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [hide, setHide] = useState(false)
    const [taskCount, setTaskCount] = useState({
        pending: 0,
        completed: 0
    })
    const accessToken = useSelector((state) => state.auth.accessToken);
    const addTask = async (e) => {
        e.preventDefault();
        const url = process.env.REACT_APP_API_URL + '/task/';
        const headers = { 'Authorization': `Bearer ${accessToken}` };
        const data = {
            title: taskInput,
            description: ""
        }
        const res = await axios.post(url, data, { headers });
        setTaskInput("")
        setSuccess(true)
        setTasks([...tasks, res.data.task])
    }
    const toggleTask = async (id, status) => {
        const url = process.env.REACT_APP_API_URL + '/task/' + id + '/update-status';
        const headers = { 'Authorization': `Bearer ${accessToken}` };
        const data = {
            status
        }
        let temp = tasks
        temp.map((task) => {
            if (task._id === id) {
                task.status = status
                console.log(task)
            }
        })
        setTasks([...temp])
        await axios.put(url, data, { headers });
    }
    const deleteTask = async (id) => {
        const url = process.env.REACT_APP_API_URL + '/task/' + id
        const headers = { 'Authorization': `Bearer ${accessToken}` };
        const res = await axios.delete(url, { headers });
        let temp = tasks.filter((task) => {
            return task._id != id
        })
        console.log(temp)
        setTasks([...temp])
    }
    const getTasks = async () => {
        setLoading(true)
        const url = process.env.REACT_APP_API_URL + '/task/';
        const headers = { 'Authorization': `Bearer ${accessToken}` };
        const res = await axios.get(url, { headers });
        res.data.sort(function (a, b) {
            if (a.status === "pending" && b.status === "completed") {
                return -1;
            } else if (a.status === "completed" && b.status === "pending") {
                return 1;
            } else {
                return 0;
            }
        });
        setTasks(res.data)
        setLoading(false)
    }
    useEffect(() => {
        getTasks()
    }, [])

    useEffect(() => {
        const data = tasks.sort(function (a, b) {
            if (a.status === "pending" && b.status === "completed") {
                return -1;
            } else if (a.status === "completed" && b.status === "pending") {
                return 1;
            } else {
                return 0;
            }
        });
        setTasks(data)
    }, [tasks])

    useEffect(() => {
        let pending = 0
        let completed = 0
        tasks.map((task) => {
            if (task.status === 'pending') pending++
            else completed++
        })
        setTaskCount({
            pending,
            completed
        })
    }, [tasks])

    const getTasksNumber = (listId) => {
        let total = 0
        let completed = 0
        console.log(listId)
        tasks.map((task) => {
            console.log(task)
            if (task?.listId?._id === listId) {
                total++
                if (task.status === 'completed') {
                    completed++
                }
            }
        })
        return `${completed}/${total}`
    }

    return (
        <div>
            <Container maxWidth="lg">
                <Typography variant="h4" sx={{ my: 2 }}>{getGreeting()}</Typography>
                <Box sx={{ my: 5, display: { xs: 'none', sm: 'block', md: 'block' } }}>
                    <Grid container spacing={2} alignItems={'stretch'} >
                        <Grid item xs={4} md={3} height={150}>
                            <Card sx={{ height: '100%' }}>
                                <div className='home__date-container'>
                                    <span className='day'>{moment().format("ddd")}</span>
                                    <span className='date'>{moment().format("D")}</span>
                                    <span className='damonthy'>{moment().format("MMMM")}</span>
                                </div>
                            </Card>
                        </Grid>
                        {lists.map((list) => {
                            return (
                                <Grid item xs={4} md={3} height={150}>
                                    <Card sx={{ height: '100%' }}>
                                        <div className='home__list-container'>
                                            <span className='title'>{list.title}</span>
                                            <span className='number'>{getTasksNumber(list._id)}</span>
                                            <span className='text'>{'Tasks completed'}</span>
                                        </div>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
                <Box sx={{ display: { xs: 'flex', sm: 'none', md: 'none' } }}>
                    <Grid container spacing={2} alignItems={'stretch'}>
                        <Grid item xs={4} md={3} height={150}>
                            <Card sx={{ height: '100%' }}>
                                <div className='home__date-container'>
                                    <span className='day'>{moment().format("ddd")}</span>
                                    <span className='date'>{moment().format("D")}</span>
                                    <span className='damonthy'>{moment().format("MMMM")}</span>
                                </div>
                            </Card>
                        </Grid>
                        {lists?.slice(0, 2).map((list) => {
                            return (
                                <Grid item xs={4} md={3} height={150}>
                                    <Card sx={{ height: '100%' }}>
                                        <div className='home__list-container mobile'>
                                            <span className='title'>{list.title}</span>
                                            <span className='number'>{getTasksNumber(list._id)}</span>
                                            <span className='text'>{'Tasks completed'}</span>
                                        </div>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
                {/* <Divider />
                <Box
                    sx={{
                        display: 'grid',
                        columnGap: 3,
                        rowGap: 1,
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        mt: 2
                    }}
                >
                    <NumberCard
                        title={'Pending Tasks'}
                        number={taskCount.pending}
                    />
                    <NumberCard
                        title={'Completed Tasks'}
                        number={taskCount.completed}
                    />
                    <NumberCard
                        title={'Total Tasks'}
                        number={tasks.length}
                    />
                </Box> 
                <Divider sx={{ mt: 2 }} /> */}

                <Typography variant="h5" sx={{ my: 2 }}>All Tasks</Typography>

                <Divider sx={{ mb: 2 }} />

                <div style={{ paddingBottom: '3rem', transition: 'all 1s ease-out' }}>
                    {loading ?
                        <Loader />
                        :
                        <Box sx={{ mb: 3 }}>
                            <form onSubmit={addTask}>
                                <AddTaskInput taskInput={taskInput} setTaskInput={setTaskInput} />
                            </form>

                            {tasks.length === 0 && <>
                                <Box height={200} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography>No tasks present. Add a new task from above.</Typography>
                                </Box>
                            </>
                            }
                            {tasks.map((task) => {
                                if (task.status === 'pending')
                                    return (
                                        <TaskCard task={task} toggleTask={toggleTask} deleteTask={deleteTask} />
                                    )
                            })}
                            <Grid item xs={4} md={3}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h5" sx={{ my: 2 }}>Completed</Typography>
                                    {!hide ? <Typography variant="caption" onClick={() => setHide(true)} sx={{ mt: 3, mr: 2, cursor: 'pointer' }}>Hide</Typography> : <Typography variant="caption" sx={{ mt: 3, mr: 2, cursor: 'pointer' }} onClick={() => setHide(false)}>Show</Typography>}
                                </Box>
                                {!hide && tasks.map((task) => {
                                    if (task.status !== 'pending')
                                        return (
                                            <TaskCard task={task} toggleTask={toggleTask} deleteTask={deleteTask} />
                                        )
                                })}
                            </Grid>
                        </Box>
                    }

                </div>
            </Container >
            <Snackbar
                open={success}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={3000}
                onClose={() => setSuccess(false)}
                message={'Task Added'}
                action={() => { }}
            />
        </div >
    )
}

export default Home
import React from 'react'
import { Box, Typography } from '@mui/material'
import './Footer.css'
import { APP_UPDATE_DATE } from '../constants'

function Footer() {
    return (
        <Box>
            <div className='footer__container' >
                <Typography align='center'>Last Updated {APP_UPDATE_DATE} | 2023 | All rights reserved. | <a href="/privacy-policy">Privacy Policy</a></Typography>
            </div>
        </Box>
    )
}

export default Footer
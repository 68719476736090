import { GET_LISTS, ADD_LIST } from '../actions/listActions';

const initialState = {
    lists: []
};

const listReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LISTS:
            return {
                ...state,
                lists: action.payload.data,
            };
        case ADD_LIST:
            return {
                ...state,
                lists: [...state.lists, action.payload.data],
            };
        default:
            return state;
    }
};

export default listReducer;
import React, { useState } from 'react'
import { Alert, Box, Button, CircularProgress, Modal, TextField, Typography } from '@mui/material'
import axios from 'axios'

function ResetModal({ setResetModalClose }) {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #000 50%',
        boxShadow: 24,
        p: 4,
    };
    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const url = process.env.REACT_APP_API_URL + '/auth/forgot-password'
            const res = await axios.post(url, { email })
            if (res?.status === 200) {
                setSuccess(res.data.message)
                setLoading(false)
            }
        } catch (error) {
            if (error.response.status === 401) {
                setError("Incorrect email. Please try again.")
                setEmail('')
                setLoading(false)
            }
            console.log(error.response)
        }
    }
    return (
        <Modal
            open={true}
            onClose={() => { }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Reset Password
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Enter your email associated with your account to reset your password.
                </Typography>
                <Box>
                    {success != '' && <Alert severity="success">{success}</Alert>}
                    <form onSubmit={onSubmit}>
                        <TextField
                            id="email"
                            label="Email"
                            type='email'
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {loading ? <CircularProgress sx={{ mt: 1.5, p: 1 }} /> : <Button variant="contained" sx={{ mt: 1.5 }} type='submit' >Reset </Button>}
                            <Button variant="outlined" sx={{ mt: 1.5, ml: 1.5 }} onClick={() => setResetModalClose(false)} >Cancel </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Modal>
    )
}

export default ResetModal
import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/authActions';
import { Link } from '@mui/material';

function Navbar() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const dispatch = useDispatch()

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar color="white" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box sx={{ flexGrow: 1 }}>
                            <Link href='/'>
                                <img src='./images/logo.png' width={150} href='/' />
                            </Link>
                        </Box>
                        {!isAuthenticated ? <>
                            <Button color="inherit" href='/register'>Register</Button>
                            <Button color="inherit" href='/login'>Login</Button>
                        </> : <>
                            <Button color="inherit" onClick={() => dispatch(logout())}>Logout</Button>
                        </>}
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    )
}

export default Navbar
import React, { useState } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, Alert, Divider, Modal, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';

function Register() {
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const onSubmit = async (e) => {
        setError('')
        setLoading(true)
        e.preventDefault()
        try {
            const url = process.env.REACT_APP_API_URL + '/auth/register'
            const res = await axios.post(url, { username, email, password })
            if (res.status === 201) {
                setSuccess(true)
                setLoading(false)
            }
        } catch (error) {
            setError(error.response.data.message)
            setLoading(false)
        }
    }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                <img src='/images/logo.png' width={250} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card sx={{ maxWidth: 500, mt: 5 }}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: 21 }} color="text.primary" gutterBottom>
                            Welcome! Register to get started.
                        </Typography>
                        {success && <Alert severity="success">Successfully registered! Login to continue</Alert>}
                        {error != "" && <Alert severity="error">{error}</Alert>}
                        <form onSubmit={onSubmit}>
                            <TextField
                                id="username"
                                label="Username"
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                id="email"
                                label="Email"
                                type='email'
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                id="password"
                                label="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                type="password"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            {!loading
                                ?
                                <Button variant="contained" sx={{ mt: 1.5, width: '100%' }} type='submit'>Register</Button>
                                :
                                <CircularProgress />
                            }

                            <Divider sx={{ my: 2 }} />
                        </form>
                        <Typography variant='body2'>Already have an account? <Link to={'/login'}>Login here</Link></Typography>
                    </CardContent>
                </Card>
            </Box>
        </div>
    )
}

export default Register
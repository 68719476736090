import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Container, Typography, Divider, Card, CardHeader, TextField, Button, Box, Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import AddTaskInput from '../../components/common/AddTaskInput'
import TaskCard from '../../components/common/TaskCard';
import Loader from '../../components/common/Loader';
import Breadcrumb from '../../components/common/Breadcrumb';

function List() {
    const params = useParams();
    const lists = useSelector((state) => state.list.lists);
    const accessToken = useSelector((state) => state.auth.accessToken);
    const [listDetails, setListDetails] = useState({})
    const [taskInput, setTaskInput] = useState("")
    const [tasks, setTasks] = useState([])
    const [loading, setLoading] = useState(false)
    const [hide, setHide] = useState(false)
    const [success, setSuccess] = useState(false)
    const addTask = async (e) => {
        e.preventDefault();
        const url = process.env.REACT_APP_API_URL + '/task/';
        const headers = { 'Authorization': `Bearer ${accessToken}` };
        const data = {
            title: taskInput,
            description: "",
            listId: listDetails._id
        }
        const res = await axios.post(url, data, { headers });
        setTaskInput("")
        setSuccess(true)
        setTasks([...tasks, res.data.task])
    }
    const getTasks = async (listId) => {
        setLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/list/getTasks?listId=${listId}`;
        const headers = { 'Authorization': `Bearer ${accessToken}` };
        const res = await axios.get(url, { headers });
        setTasks(res.data)
        setLoading(false)
    }
    const toggleTask = async (id, status) => {
        const url = process.env.REACT_APP_API_URL + '/task/' + id + '/update-status';
        const headers = { 'Authorization': `Bearer ${accessToken}` };
        const data = {
            status
        }
        let temp = tasks
        temp.map((task) => {
            if (task._id === id) {
                task.status = status
            }
        })
        setTasks([...temp])
        return await axios.put(url, data, { headers });
    }
    const deleteTask = async (id) => {
        const url = process.env.REACT_APP_API_URL + '/task/' + id
        const headers = { 'Authorization': `Bearer ${accessToken}` };
        const res = await axios.delete(url, { headers });
        let temp = tasks.filter((task) => {
            return task._id != id
        })
        setTasks([...temp])
    }
    useEffect(() => {
        lists.map((list) => {
            if (list._id === params.id) {
                setListDetails(list)
                getTasks(params.id);
            }
        })
    }, [params.id])

    return (
        <div>
            <Container sx={{ mt: 2 }}>
                <Breadcrumb parentLabel={'List'} parentLink={'/'} childLabel={listDetails.title} />
                <Typography variant="h4" sx={{ my: 1 }}>{listDetails?.title}</Typography>
                <Divider sx={{ my: 2 }} />
                {loading ?
                    <Loader /> :
                    <Box sx={{ mb: 3 }}>
                        <form onSubmit={addTask}>
                            <AddTaskInput taskInput={taskInput} setTaskInput={setTaskInput} />
                        </form>

                        {tasks.length === 0 && <>
                            <Box height={200} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography>No tasks present. Add a new task from above.</Typography>
                            </Box>
                        </>
                        }
                        {tasks.map((task) => {
                            if (task.status === 'pending')
                                return (
                                    <TaskCard task={task} toggleTask={toggleTask} deleteTask={deleteTask} />
                                )
                        })}
                        {tasks.filter(task => task.status === 'completed').length > 0 &&
                            <Grid item xs={4} md={3}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h5" sx={{ my: 2 }}>Completed</Typography>
                                    {!hide ? <Typography variant="caption" onClick={() => setHide(true)} sx={{ mt: 3, mr: 2, cursor: 'pointer' }}>Hide</Typography> : <Typography variant="caption" sx={{ mt: 3, mr: 2, cursor: 'pointer' }} onClick={() => setHide(false)}>Show</Typography>}
                                </Box>
                                {!hide && tasks.map((task) => {
                                    if (task.status !== 'pending')
                                        return (
                                            <TaskCard task={task} toggleTask={toggleTask} deleteTask={deleteTask} />
                                        )
                                })}
                            </Grid>}
                    </Box>
                }
            </Container>
        </div>
    )
}

export default List
import React, { useState } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, Alert, Divider, CircularProgress } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { login } from '../../actions/authActions';

function ResetPassword() {
    const [password, setPassword] = useState('')
    const [cPassword, setCPassword] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const onSubmit = async (e) => {
        e.preventDefault()
        if (password != cPassword) {
            setError('Passwords do not match.')
            return
        }
        setLoading(true)
        try {
            const url = process.env.REACT_APP_API_URL + '/auth/reset-password/' + params.token
            const res = await axios.post(url, { newPassword: password })
            if (res?.status === 200) {
                setSuccess(res.data.message + ' You may now login.')
                setLoading(false)
            }
        } catch (error) {
            setError("Something went wrong. Please try again.")
            setPassword('')
            setCPassword('')
            setLoading(false)
            console.log(error.response)
        }
    }
    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                <img src='/images/logo.png' width={250} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card sx={{ maxWidth: 500, mt: 5 }}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: 21 }} color="text.primary" gutterBottom>
                            Enter new password for your account.
                        </Typography>
                        {error != "" && <Alert severity="error" sx={{ width: '100%' }}>{error}</Alert>}
                        {success != "" && <Alert severity="success">{success}</Alert>}
                        <form onSubmit={onSubmit}>
                            <TextField
                                id="password"
                                label="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                type="password"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                id="password"
                                label="Confirm Password"
                                value={cPassword}
                                onChange={(e) => setCPassword(e.target.value)}
                                required
                                type="password"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            {!loading
                                ?
                                <Button variant="contained" sx={{ mt: 1.5, width: '100%' }} type='submit'>Reset</Button>
                                :
                                <CircularProgress />
                            }
                            <Divider sx={{ my: 2 }} />
                        </form>
                        <Typography variant='body2'><Link to={'/login'}>Click here to login</Link></Typography>
                    </CardContent>
                </Card>
            </Box>
        </div>
    )
}

export default ResetPassword
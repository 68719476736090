import React from 'react'
import { AppBar, Box, Button, Card, Container, Grid, IconButton, Toolbar, Typography } from '@mui/material'
import './Main.css'

function Main() {
    return (
        <>
            <AppBar position='fixed' color="white" className='main__container'>
                <Container maxWidth="xl" sx={{ p: 2 }}>
                    <Toolbar>
                        <Box sx={{ flexGrow: 1 }}>
                            <img src='/images/logo.png' width={150} />
                        </Box>
                        <Button variant='outlined' color="inherit" href='/register'>Register</Button>
                        <Button variant='outlined' color="inherit" href='/login'>Login</Button>
                    </Toolbar>
                </Container>
            </AppBar>
            <Container>
                <div className='main__heading-container'>
                    <span className='main__title'> Supercharge your productivity <br /> with QwikTasks. </span>
                    <span className='subtitle'>Unlock your full potential with QwikTasks <br />effortlessly manage tasks and achieve More!</span>
                    <Button variant='contained' className='get-started' href='/register'>Get started</Button>
                    <img src='/images/header.png' />
                </div>
            </Container>
            <Box sx={{ backgroundColor: '#f8f8f8' }}>
                <Container >
                    <div className='main__feature-container'>
                        <span className='main__title'>Features.</span>
                        <Grid container sx={{ mb: 10, mt: 5 }}>
                            <Grid item xs={6} height={150} sx={{ my: 2 }}>
                                <Card sx={{ height: '100%', m: 2 }}>
                                    <div className='feature-card'>
                                        <span className='feature-title'>Task Organization Made Easy</span>
                                        <span className='feature-subtitle'>Effortlessly manage your to-do list, projects, and goals in one place.</span>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={6} height={150} sx={{ my: 2 }}>
                                <Card sx={{ height: '100%', m: 2 }}>
                                    <div className='feature-card'>
                                        <span className='feature-title'>Deadline Reminders & Notifications</span>
                                        <span className='feature-subtitle'>Effortlessly manage your to-do list, projects, and goals in one place.</span>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={6} height={150} sx={{ my: 2 }}>
                                <Card sx={{ height: '100%', m: 2 }}>
                                    <div className='feature-card'>
                                        <span className='feature-title'>Task Organization Made Easy</span>
                                        <span className='feature-subtitle'>Effortlessly manage your to-do list, projects, and goals in one place.</span>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={6} height={150} sx={{ my: 2 }}>
                                <Card sx={{ height: '100%', m: 2 }}>
                                    <div className='feature-card'>
                                        <span className='feature-title'>Task Organization Made Easy</span>
                                        <span className='feature-subtitle'>Effortlessly manage your to-do list, projects, and goals in one place.</span>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </Box >
            <Container>
                <div className='main__join-container'>
                    <span className='main__title'>Join us.</span>
                    <div className='beta-section'>
                        <span>We are currently in Beta and are open for all.</span>
                        <div>
                            <Button variant='contained' className='get-started' href='/register'>Get started</Button>
                        </div>
                    </div>
                    <span className='made-in-india'>Made with ❤️ in India.</span>
                </div>
            </Container >
            <Box sx={{ my: 2 }} >
                <Typography align='center' fontSize={13}> 2023 | All rights reserved. | <a href="/privacy-policy">Privacy Policy</a></Typography>
            </Box>
        </>
    )
}

export default Main
export const GET_LISTS = 'GET_LISTS';
export const ADD_LIST = 'ADD_LIST';

export const getLists = (data) => {
    return {
        type: GET_LISTS,
        payload: data,
    };
};

export const addList = (data) => {
    return {
        type: ADD_LIST,
        payload: data,
    };
};


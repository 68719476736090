import React from 'react'
import { Card, CardHeader, Checkbox, Chip, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

function TaskCard({ task, toggleTask, deleteTask }) {
    return (
        <Card sx={{ my: 1 }} key={task._id}>
            <CardHeader
                avatar={
                    <Checkbox checked={task.status === 'completed'} onChange={(e) => toggleTask(task._id, task.status === 'completed' ? 'pending' : 'completed')} />
                }
                action={
                    <>
                        {task?.listId?.title && <Chip label={task.listId.title} variant="outlined" />}
                        <IconButton aria-label="settings" onClick={() => deleteTask(task._id)}>
                            <ClearIcon sx={{ color: '#ababab' }} />
                        </IconButton>
                    </>
                }
                title={task.title}
            />
        </Card>
    )
}

export default TaskCard;
import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { addList } from '../../actions/listActions'

function CreateListModal({ open, setOpen }) {
    const accessToken = useSelector((state) => state.auth.accessToken);
    const [listName, setListName] = useState("");
    const dispatch = useDispatch();
    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = process.env.REACT_APP_API_URL + '/list/';
            const headers = { 'Authorization': `Bearer ${accessToken}` };
            const data = {
                title: listName,
            };
            const res = await axios.post(url, data, { headers })
            console.log(res.data.data)
            dispatch(addList(res.data))
            setOpen(false)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Create a new list.</DialogTitle>
                <form onSubmit={onSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            Lists help in segregating your tasks. You can create as many lists you want.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Enter list name"
                            type="email"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => setListName(e.target.value)}
                            sx={{ mt: 3 }}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button type="submit" onClick={(e) => onSubmit(e)}>Create</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default CreateListModal
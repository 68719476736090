import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ListIcon from '@mui/icons-material/List';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChecklistIcon from '@mui/icons-material/Checklist';
import React, { useState } from 'react'
import { Divider, Drawer, List, ListItemButton, ListItemText, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CreateListModal from '../components/lists/CreateListModal';

function BottomBar() {
    const [value, setValue] = useState(0);
    const [drowerOpen, setDrowerOpen] = useState(false);
    const [openCreateListModal, setOpenCreateListModal] = useState(false)
    const lists = useSelector((state) => state.list.lists);
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div className='bottom-navigation'>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="All Tasks" icon={<ChecklistIcon />} onClick={() => navigate('/')} />
                    <BottomNavigationAction label="Lists" icon={<ListIcon />} onClick={() => setDrowerOpen(true)} />
                    <BottomNavigationAction label="My Account" icon={<AccountCircleIcon />} onClick={() => navigate('/account')} />
                </BottomNavigation>
                <Drawer
                    anchor="bottom"
                    open={drowerOpen}
                    onClose={() => { setDrowerOpen(false) }}
                >
                    <List>
                        {lists.map((list) => {
                            return (
                                <List component="div" disablePadding>
                                    <ListItemButton selected={location.pathname === '/list/' + list._id} sx={{ pl: 4 }} onClick={() => { navigate(`/list/${list._id}`); setDrowerOpen(false) }}>
                                        <ListItemText primary={list.title} />
                                    </ListItemButton>


                                </List>
                            )
                        })}
                        <Divider />
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => { setOpenCreateListModal(true); setDrowerOpen(false) }}>
                                <ListItemText primary={'Add a new list'} />
                            </ListItemButton>
                        </List>
                    </List>
                </Drawer>
                <CreateListModal open={openCreateListModal} setOpen={setOpenCreateListModal} />
            </Paper>
        </div>
    )
}

export default BottomBar
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import axios from 'axios';
import Navbar from './components/Navbar'
import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import Home from './pages/home/Home';
import { refreshAccessToken, isTokenValid } from './utilities/authValidation'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { login } from './actions/authActions';
import { getLists } from './actions/listActions';
import Footer from './components/Footer';
import ResetPassword from './pages/auth/ResetPassword';
import Sidebar from './components/Sidebar';
import { Box, CssBaseline, Toolbar } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import List from './pages/lists/List';
import MyAccount from './pages/account/MyAccount';
import BottomBar from './layouts/BottomBar';
import Main from './pages/landing/Main';
import PrivacyPolicy from './pages/other/PrivacyPolicy';

const theme = createTheme({
  palette: {
    primary: {
      main: '#20A4F3',
    },
    secondary: {
      main: '#f50057',
    },
    white: {
      main: ' #fff'
    }
  },
  shape: {
    borderRadius: 5
  }
});

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true);

  const getList = async () => {
    const url = process.env.REACT_APP_API_URL + '/list/';
    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const { data } = await axios.get(url, { headers })
    await dispatch(getLists(data))
    console.log(data)
  }
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (isTokenValid()) {
      const performLogin = async () => {
        await dispatch(login(token));
        setIsLoading(false);
      };
      performLogin();
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      getList()
    }
  }, [accessToken])

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='app'>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {isAuthenticated && <Navbar />}
            {isAuthenticated && <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              <Sidebar />
            </Box>}
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Toolbar />
              <Routes>
                <Route path='/' element={isAuthenticated ? <Home /> : <Navigate to="/home" />} />
                <Route path='/home' element={<Main />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/list/:id' element={isAuthenticated ? <List /> : <Navigate to="/login" />} />
                <Route path='/account' element={isAuthenticated ? <MyAccount /> : <Navigate to="/login" />} />
                <Route path='/register' element={<Register />} />
                <Route path='/login' element={<Login />} />
                <Route path='/reset-password/:token' element={<ResetPassword />} />
              </Routes>
              {isAuthenticated &&
                <Footer sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} />
              }
            </Box>
            {isAuthenticated &&
              <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                <BottomBar />
              </Box>
            }



          </Box>
        </BrowserRouter>


      </ThemeProvider>
    </div>
  );
}

export default App;
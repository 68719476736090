import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BugReportIcon from '@mui/icons-material/BugReport';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';
import { Drawer, Toolbar, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, Divider, ListSubheader, Collapse, Alert, Typography, Chip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateListModal from './lists/CreateListModal';

const drawerWidth = 260;

function Sidebar() {
    const [openList, setOpenList] = useState(true)
    const [openCreateListModal, setOpenCreateListModal] = useState(false)
    const location = useLocation();
    const lists = useSelector((state) => state.list.lists);
    const navigate = useNavigate()
    const handleClick = () => {
        setOpenList(!openList);
    };
    useEffect(() => {
        console.log(location.pathname)
    }, [])
    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Menu
                        </ListSubheader>
                    }
                >
                    <ListItem disablePadding>
                        <ListItemButton selected={location.pathname === '/'} onClick={() => navigate('/')}>
                            <ListItemIcon>
                                <DoneAllIcon />
                            </ListItemIcon>
                            <ListItemText primary={'All Tasks'} />
                        </ListItemButton>
                    </ListItem>

                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <ListIcon />
                        </ListItemIcon>
                        <ListItemText primary="Lists" />
                        {openList ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openList} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => setOpenCreateListModal(true)}>
                                <ListItemIcon>
                                    <AddIcon />
                                </ListItemIcon>
                                <ListItemText primary="Add List" />
                            </ListItemButton>
                        </List>
                        {lists.map((list) => {
                            return (
                                <List component="div" disablePadding>
                                    <ListItemButton selected={location.pathname === '/list/' + list._id} sx={{ pl: 4 }} onClick={() => navigate(`/list/${list._id}`)}>
                                        <ListItemIcon>
                                            <CircleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={list.title} />
                                    </ListItemButton>
                                </List>
                            )
                        })}
                        {lists.length === 0 && (
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }} disabled>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary={'No lists created'} />
                                </ListItemButton>
                            </List>
                        )
                        }
                    </Collapse>
                </List>
                <Divider />
                <Typography sx={{ m: 2 }}>Latest Updates</Typography>


                <Alert severity="info" icon={false} sx={{ m: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Chip label={'New'} />
                        <Typography variant='caption'>12th October, 2023</Typography>
                    </Box>
                    <Box sx={{ m: 1 }}>
                        My Account page and feature is now live
                    </Box>
                </Alert>
            </Box>
            <Box sx={{ position: 'fixed', bottom: '0.5rem', width: '260px' }}>
                <ListItem disablePadding>
                    <ListItemButton selected={location.pathname === '/account'} onClick={() => navigate(`/account`)}>
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={'My Account'} />
                    </ListItemButton>
                </ListItem>
            </Box>
            <CreateListModal open={openCreateListModal} setOpen={setOpenCreateListModal} />
        </Drawer >
    )
}

export default Sidebar
import jwtDecode from 'jwt-decode';
import axios from 'axios';

export const isTokenValid = () => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
        return false; // No token found
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds

    return decodedToken.exp > currentTime; // Check if token is not expired
};


export const refreshAccessToken = async () => {
    try {
        const refreshToken = localStorage.getItem('accessToken');
        const response = await axios.post('/api/refresh-token', { refreshToken });
        const newAccessToken = response.data.accessToken;

        localStorage.setItem('accessToken', newAccessToken);

        return newAccessToken;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
};
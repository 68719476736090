import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from './reducers/authReducer';
import listReducer from './reducers/listReducer';


const rootReducer = combineReducers({
    auth: authReducer,
    list: listReducer
});

const store = createStore(rootReducer, composeWithDevTools());

export default store;